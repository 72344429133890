<template>
  <dsf-page-layout>
    <app-layout-sidebar
      v-if="!isMobile"
      slot="menu"
      class="dsf-hidden-mobile"
    />

    <orchestrated-layout
      v-if="shouldRenderFallbackHeader"
      :fallback="true"
      slot="navbar"
    ></orchestrated-layout>

    <app-layout-mobile-sidebar
      v-if="isMobile && shouldRenderMobileMenu"
      slot="menu"
      @close="hideMobileMenu"
      class="dsf-hidden-tablet dsf-hidden-desktop z-[var(--dsf-z-index-layout)]"
    />

    <router-view
      slot="content"
      class="content dsf-theme-default"
      data-test="sennTMS-shell-content"
    />
  </dsf-page-layout>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import AppLayoutMobileSidebar from './AppLayoutMobileSidebar.vue'
import AppLayoutSidebar from './AppLayoutSidebar.vue'
import { useDevice } from '@/composables/useDevice'

import {
  hideMobileMenu,
  shouldRenderMobileMenu,
  shouldRenderFallbackHeader,
} from '@/components/orchestrated-layout'

const { device } = useDevice()

const isMobile = computed(() => device.value === 'mobile')
</script>
