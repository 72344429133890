import { watch } from 'vue'

import {
  INotificationCenterData,
  INotificationCenterMicrofrontendData,
  MountNotifications,
  SharedDataType,
} from '@sennder/senn-node-microfrontend-interfaces'
import {
  cloneReactiveToRaw,
  MicroFrontendLogger,
} from '@sennder/shell-utilities'

import errorsHandler from '@/services/errors-handler'
import { getStateCallbacks } from '@/store'
import { AppAnalyticsProvider } from './analyticsProvider'
import { wrapAuthWithLogging } from '@/modules/utils'
import { getStateData } from '@/store'
import router from '@/router'
import { auth } from '@/modules/auth'
import { loggerInstance } from './logger'
import { microfrontends } from '@/config/microfrontends'

export const notificationsModule = microfrontends.notifications

let mountFunctionPromise: Promise<{ mount: MountNotifications }> | undefined
let unwatch: (() => void) | null = null

async function getMountFunction() {
  if (!mountFunctionPromise) {
    mountFunctionPromise = notificationsModule.getModule<{
      mount: MountNotifications
    }>('./notificationcentermfApp')
  }
  return (await mountFunctionPromise).mount
}

function getNotificationCenterData(): INotificationCenterData {
  const { language, featureFlags } = getStateData()
  return {
    shell: SharedDataType.SENN_TMS,
    language,
    featureFlags,
  }
}

async function loadNotificationsCenter(element: HTMLElement) {
  const mountFunction = await getMountFunction()

  const logger = new MicroFrontendLogger(
    {
      module: 'notifications',
      codeOwners: 'frontend-systems',
    },
    () => loggerInstance
  )

  const { getAuthHeader } = getStateCallbacks()

  const microfrontendData: INotificationCenterMicrofrontendData = {
    data: getNotificationCenterData(),
    callbacks: {
      getAuthHeader: wrapAuthWithLogging(getAuthHeader, logger),
      getAuthToken: wrapAuthWithLogging(async () => {
        if (!auth.value) {
          throw new Error(
            '[notifications - getAuthToken]: Auth module is not loaded'
          )
        }
        return auth.value.getAuthToken({
          audience: 'https://api.cloud.sennder.com',
        })
      }, logger),
      getCommonHeaders: getStateCallbacks().getCommonHeaders,
      navigate: (route) => {
        router.push(route)
      },
    },
    providers: {
      logger,
      analytics: new AppAnalyticsProvider({
        module: 'notifications',
        submodule: '',
      }),
    },
  }
  const mountResult = mountFunction(element, microfrontendData)

  unwatch = watch(
    () => getStateData(),
    () => {
      mountResult.onSharedDataChanged(
        cloneReactiveToRaw(getNotificationCenterData())
      )
    },
    { deep: true }
  )
}

export async function initNotifications(element: HTMLElement) {
  try {
    await loadNotificationsCenter(element)
  } catch (error: any) {
    errorsHandler(error)
  }
}

export function stopNotifications() {
  unwatch?.()
}
