import { OrgType } from '@sennder/senn-node-microfrontend-interfaces'
import { UserAccessHandler } from '@sennder/shell-utilities'

import { getStateData, getStateFeatureFlags } from '@/store'

/**
 * Access handlers are functions which return a boolean. They can be used as menu visibility handlers, and called in route guards.
 *
 * NAMING CONVENTIONS
 *
 * Access handler name should be a sentence which describes what access it checks, or user role it checks.
 *
 * - userIs{Role}: Check if the user has a specific role
 * - canAccess{Page}: Check if the user has access to the page
 */

export const canAccessAnalysisBoard: UserAccessHandler = () =>
  !!getStateFeatureFlags()['ENABLE_ANALYSIS-BOARD']

export const canAccessCharteringPages = ((org = getStateData().org) => {
  return !!(
    org?.orgType === OrgType.CHARTERING_OFFICE &&
    getStateFeatureFlags()['enable-chartering-mfs-senn-tms']
  )
}) satisfies UserAccessHandler

export const canAccessSennlab: UserAccessHandler = () =>
  !!getStateFeatureFlags()['ENABLE_OCTOPUS-SENNLAB']

export const canAccessShipperContactsPages: UserAccessHandler = () =>
  !!getStateFeatureFlags()['enable-shipper-contacts-mfs']
