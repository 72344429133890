import { amplitude } from '@sennder/senn-node-analytics-frontend'
import { AmplitudeLogLevel } from '@sennder/senn-node-analytics-frontend/lib/types'
import { AppAmplitudeLogger } from '@sennder/shell-utilities'

import { AMPLITUDE_API_KEY, TENANT, isUatEnv } from '@/common/config'
import { getStateData, getStateUser } from '@/store'
import { executeSafelyWithLogging } from './errors-handler'
import { logger } from './logger/loggers'

export function initAnalytics() {
  return executeSafelyWithLogging(() => {
    if (isUatEnv()) {
      logger.warn(
        '[tms-shell - Analytics]: Amplitude is not available in UAT environment',
        {}
      )
      return
    }
    if (!AMPLITUDE_API_KEY) {
      throw new Error('[tms-shell - Analytics]: AMPLITUDE_API_KEY not found')
    }
    amplitude.init({
      token: AMPLITUDE_API_KEY,
      appName: 'tms',
      amplitudeConfig: {
        minIdLength: 3,
        loggerProvider: new AppAmplitudeLogger(logger),
        logLevel: AmplitudeLogLevel.Warn,
      },
    })
  }, 'amplitude.init')
}

export function identifyUserInAnalytics(onLogin: boolean) {
  const analyticsFeaturesFlags = Object.fromEntries(
    Object.entries(getStateData().featureFlags).filter(([flag]) =>
      flag.startsWith('exp')
    )
  )
  const user = getStateUser()

  const analyticsUser = {
    tenant: TENANT,
    userId: user.uuid || user.id.toString(),
    userName: user.username,
    language: user.language,
    groupNames: user.roles
      ?.filter((role) => role.name)
      .map((role) => role.name),
    resolution: `${window.screen.width}x${window.screen.height}`,
    timezone: user.timezone,
    ...analyticsFeaturesFlags,
  }

  const deprecatedUserProperties: string[] = [
    'cookies',
    'featureFlags',
    'firstLogin',
    'firstName',
    'fullNameWithoutTitle',
    'id',
    'inCustomerGroup',
    'inFinanceGroup',
    'inStaffGroup',
    'initial_referrer',
    'initial_referring_domain',
    'initial_utm_medium',
    'initial_utm_source',
    'lastName',
    'paymentTermPopUpDaysPast',
    'permissions',
    'positionTitle',
    'selected',
    'shipperId',
    'tms id',
    'referrer',
    'referring_domain',
    'uiLanguage',
    'username',
    'uuid',
  ]

  executeSafelyWithLogging(
    () =>
      amplitude.identifyUser(
        analyticsUser,
        onLogin,
        undefined,
        deprecatedUserProperties
      ),
    'amplitude.identifyUser'
  )
}

export const resetAnalyticsSession = () => {
  executeSafelyWithLogging(() => amplitude.endSession(), 'amplitude.endSession')
  initAnalytics()
}

// Used when the user rejects analytics cookies,
// all user data will be removed from the analytics integration as well as any event submissions will be terminated
export const stopAnalytics = () => {
  executeSafelyWithLogging(
    () => amplitude.stopTracking(),
    'amplitude.stopTracking'
  )
}
